import { IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import * as momentTimezone from 'moment-timezone';
import { createNumberMask } from 'text-mask-addons';
import { destroy, initialize } from 'tsdi';
import { injectTSDI } from '../core/tsdi';
import { MultiBrandStore } from '../core/brand/store';
import {
    MultiBrandPlaceholder,
    multiBrandPlaceholderResolver
} from '../core/brand/placeholder';
import { LocaleStore } from './create-locale-store';
import type {
    CountryCodeEnum,
    CurrencyCodeEnum,
    DATETIME,
    IsoDateString,
    KeyValuePairStringString,
    MoneyDto,
    Option,
    TaxPercentage
} from './dtos';
import { isTranslatable, Translatable } from './translatable';
import { nbsp } from './util';

interface NumberMaskConfig {
    prefix?: string;
    suffix?: string;
    includeThousandsSeparator?: boolean;
    thousandsSeparatorSymbol?: string;
    allowDecimal?: boolean;
    decimalSymbol?: string;
    decimalLimit?: number;
    integerLimit: number | undefined | null;
    requireDecimal?: boolean;
    allowNegative?: boolean;
    allowLeadingZeroes?: boolean;
}

interface DateTimeOptions {
    year?: string;
    month?: string;
    day?: string;
    hour?: string;
    minute?: string;
}

export type DayOfWeekEnum =
    | 'MONDAY'
    | 'TUESDAY'
    | 'WEDNESDAY'
    | 'THURSDAY'
    | 'FRIDAY'
    | 'SATURDAY'
    | 'SUNDAY';

export const localesPathMappings = {
    'de-DE': 'de',
    'en-GB': 'en',
    'en-CA': 'en',
    'en-HK': 'en',
    'it-IT': 'it',
    // 'ru-RU': 'ru', // currently only 13%
    'pl-PL': 'pl',
    // 'sv-SE': 'sv', // currently only 13%
    // 'tr-TR': 'tr', // currently only 65%
    'es-ES': 'es',
    'es-CO': 'es',
    'fr-FR': 'fr',
    'fr-CA': 'fr',
    'nl-NL': 'nl',
    'cs-CZ': 'cs',
    'sl-SI': 'sl',
    'hu-HU': 'hu'
};

function isPossiblyUntranslatedKey(candidate: string): boolean {
    return candidate.includes('.') && !candidate.includes(' ');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function replacePlaceHolder(translated: string, params: any): string {
    const { brandType } = injectTSDI(MultiBrandStore);

    const brandReplacedTranslation = Object.values(
        MultiBrandPlaceholder
    ).reduce((acc, placeholder) => {
        const replacementValue =
            multiBrandPlaceholderResolver[placeholder](brandType);
        return acc.split(placeholder).join(replacementValue);
    }, translated);

    if (!params) {
        return brandReplacedTranslation;
    }
    return brandReplacedTranslation
        .replace(/\${/g, '___dollar___bracket___')
        .replace(/{\w}/g, (v) => params[v[1]])
        .replace(/___dollar___bracket___/g, '${');
}

export interface CreateI18nStoreConfig {
    locallySupportedLocales: string[];

    localeStore(): LocaleStore;

    storage(): Storage;

    isLoggedIn(): boolean;

    isDev?(): boolean;

    isNoI18nMode?(): boolean;

    getSupportedLocales(): Promise<string[]>;

    getCountries?(
        country: CountryCodeEnum
    ): Promise<KeyValuePairStringString[]>;

    onUntranslatedKey?(key: string): void;
}

export type I18nStore = ReturnType<typeof createI18nStore>['prototype'];

export const createI18nStore = (config: CreateI18nStoreConfig) => {
    const {
        localeStore: getLocaleStore,
        storage: getStorage,
        isDev = () => false,
        isNoI18nMode = () => false
    } = config;

    class I18nStore {
        constructor() {
            makeObservable(this, {
                supportedLocales: observable,
                version: observable,
                _countries: observable,
                formatPercent: observable,
                formatPercent2Digits: observable,
                formatDateTime: observable,
                formatDate: observable,
                formatTime: observable,
                formatZonedDateTime: observable,
                formatZonedDate: observable,
                formatZonedTime: observable,
                formatDecimal: observable,
                dateTimeInputDateFormat: observable,
                dateTimeInputTimeFormat: observable,
                dateTimeInputDateTimeFormat: observable,
                translate: observable,
                hasTranslation: observable
            });
        }

        private get multiBrandStore() {
            return injectTSDI(MultiBrandStore);
        }

        private get localeStore() {
            return getLocaleStore();
        }

        public supportedLocales: string[] = [];

        public version = 0;

        public _countries: KeyValuePairStringString[] = [];

        public get countries() {
            if (!config.getCountries) {
                throw new Error(
                    "Countries won't be supported unless getCountries is included into configuration"
                );
            }

            return this._countries;
        }

        private reactionDisposers: IReactionDisposer[] = [];

        public formatPercent: (
            value: undefined | number | TaxPercentage
        ) => string = (value) => {
            if (value === undefined) {
                return '-';
            }
            return value.toString();
        };

        public formatPercent2Digits: (
            value: undefined | number | TaxPercentage
        ) => string = (value) => {
            if (value === undefined) {
                return '-';
            }
            return value.toString();
        };

        public formatDateTime: (value: Date) => string = (value) =>
            value.toString();

        public formatDate: (value: Date) => string = (value) =>
            value.toString();

        public formatTime: (value: Date) => string = (value) =>
            value.toString();

        public formatZonedDateTime: (
            value: IsoDateString<DATETIME>,
            timezone: string
        ) => string = (value) => value;

        public formatZonedDate: (
            value: IsoDateString<DATETIME>,
            timezone: string
        ) => string = (value) => value;

        public formatZonedTime: (
            value: IsoDateString<DATETIME>,
            timezone: string
        ) => string = (value) => value;

        public formatDecimal: (
            value: number | undefined,
            disableGrouping?: boolean
        ) => string = (value) => (value === undefined ? '-' : value.toString());

        public formatInteger = (value: number | undefined): string => {
            if (value === undefined) {
                return '-';
            }
            return value.toLocaleString(this.currentLocale, {
                useGrouping: true
            });
        };

        public formatWeekday = (
            weekday: DayOfWeekEnum | undefined,
            options: { shorthand?: boolean } = {}
        ): string => {
            const suffix = options.shorthand ? '.short' : '';

            switch (weekday) {
                case 'MONDAY':
                    return this.translate(`commons.dayofweek.monday${suffix}`);
                case 'TUESDAY':
                    return this.translate(`commons.dayofweek.tuesday${suffix}`);
                case 'WEDNESDAY':
                    return this.translate(
                        `commons.dayofweek.wednesday${suffix}`
                    );
                case 'THURSDAY':
                    return this.translate(
                        `commons.dayofweek.thursday${suffix}`
                    );
                case 'FRIDAY':
                    return this.translate(`commons.dayofweek.friday${suffix}`);
                case 'SATURDAY':
                    return this.translate(
                        `commons.dayofweek.saturday${suffix}`
                    );
                case 'SUNDAY':
                    return this.translate(`commons.dayofweek.sunday${suffix}`);
                default:
                    return '-';
            }
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        public dateTimeInputDateFormat: () => string = () => undefined as any;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        public dateTimeInputTimeFormat: () => string = () => undefined as any;

        public dateTimeInputDateTimeFormat: () => string = () =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            undefined as any;

        public translate: (
            key: string | Translatable | null | undefined,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...params: any[]
        ) => string = (key) => {
            if (!key) {
                return '';
            }
            return key.toString();
        };

        public hasTranslation: (
            key: string | null | undefined,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...params: any[]
        ) => boolean = () => false; // eslint-disable-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style

        public get percentMaskConfig(): NumberMaskConfig {
            const {
                decimalSymbol,
                thousandsSeparatorSymbol,
                percentPrefix: prefix,
                percentSuffix: suffix
            } = this.localeStore;
            return {
                prefix,
                suffix,
                integerLimit: null,
                allowDecimal: true,
                decimalSymbol,
                decimalLimit: 2,
                allowNegative: false,
                thousandsSeparatorSymbol,
                allowLeadingZeroes: true
            };
        }

        public get percentMask(): (value: string) => (string | RegExp)[] {
            return createNumberMask(this.percentMaskConfig);
        }

        public get currencyMaskConfig(): NumberMaskConfig {
            const {
                currencyPrefix: prefix,
                currencySuffix: suffix,
                thousandsSeparatorSymbol,
                decimalSymbol
            } = this.localeStore;

            return {
                prefix,
                suffix,
                integerLimit: null,
                allowDecimal: true,
                decimalSymbol,
                decimalLimit: 2,
                allowNegative: false,
                thousandsSeparatorSymbol,
                allowLeadingZeroes: true
            };
        }

        public get currencyMask(): (value: string) => (string | RegExp)[] {
            return createNumberMask(this.currencyMaskConfig);
        }

        public get decimalMaskConfig(): NumberMaskConfig {
            const { decimalSymbol, thousandsSeparatorSymbol } =
                this.localeStore;
            return {
                prefix: '',
                suffix: '',
                integerLimit: null,
                allowDecimal: true,
                decimalSymbol,
                decimalLimit: 2,
                allowNegative: false,
                thousandsSeparatorSymbol,
                allowLeadingZeroes: true
            };
        }

        public get decimalMask(): (value: string) => (string | RegExp)[] {
            return createNumberMask(this.decimalMaskConfig);
        }

        public get minuteMaskConfig(): NumberMaskConfig {
            const { thousandsSeparatorSymbol } = this.localeStore;
            switch (this.currentLang) {
                default:
                case 'de':
                    return {
                        prefix: '',
                        suffix: ` ${this.translate('commons.minutes.short')}`,
                        integerLimit: null,
                        allowDecimal: false,
                        allowNegative: false,
                        thousandsSeparatorSymbol,
                        allowLeadingZeroes: true
                    };
            }
        }

        public get integerMaskConfig(): NumberMaskConfig {
            switch (this.currentLang) {
                default:
                case 'de':
                    return {
                        prefix: '',
                        suffix: '',
                        integerLimit: null,
                        allowDecimal: false,
                        allowNegative: false,
                        allowLeadingZeroes: true
                    };
            }
        }

        public get countMaskConfig(): NumberMaskConfig {
            const { thousandsSeparatorSymbol } = this.localeStore;
            return {
                prefix: '',
                suffix: ' x',
                integerLimit: null,
                allowDecimal: false,
                allowNegative: false,
                thousandsSeparatorSymbol,
                allowLeadingZeroes: true
            };
        }

        public get minuteMask(): (value: string) => (string | RegExp)[] {
            return createNumberMask(this.minuteMaskConfig);
        }

        public get defaultDateFormatPlaceholder(): string {
            const { translate } = this;

            const placeholderDateFormat = this.getDateFormat({
                year: 'YY',
                month: 'MM',
                day: 'DD'
            });

            const d = translate('commons.day')[0].toUpperCase();
            const m = translate('commons.month')[0].toUpperCase();
            const y = translate('commons.years')[0].toUpperCase();

            return placeholderDateFormat
                .replace(/D/g, d)
                .replace(/M/g, m)
                .replace(/Y/g, y);
        }

        public get defaultDateFormat(): string {
            return this.getDateFormat({ year: 'Y', month: 'm', day: 'd' });
        }

        public get defaultTimeFormat(): string {
            return this.getDateFormat({ hour: 'H', minute: 'i' });
        }

        public get defaultDateTimeFormat(): string {
            return this.getDateFormat({
                year: 'Y',
                month: 'm',
                day: 'd',
                hour: 'H',
                minute: 'i'
            });
        }

        public getDateFormat = ({
            year,
            month,
            day,
            hour,
            minute
        }: DateTimeOptions): string => {
            if (year && month && day && hour && minute) {
                return new Date(Date.UTC(1983, 11, 23, 16, 59, 0))
                    .toLocaleString(this.currentLocale, {
                        hour12: false,
                        timeZone: 'UTC',
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                    .replace('23', day)
                    .replace('12', month)
                    .replace('1983', year)
                    .replace('16', hour)
                    .replace('59', minute);
            }

            if (year && month && day && !hour && !minute) {
                return new Date(Date.UTC(1983, 11, 23, 16, 59, 0))
                    .toLocaleString(this.currentLocale, {
                        hour12: false,
                        timeZone: 'UTC',
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    })
                    .replace('23', day)
                    .replace('12', month)
                    .replace('1983', year);
            }

            if (!year && !month && !day && hour && minute) {
                return new Date(Date.UTC(1983, 11, 23, 16, 59, 0))
                    .toLocaleString(this.currentLocale, {
                        hour12: false,
                        timeZone: 'UTC',
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                    .replace('16', hour)
                    .replace('59', minute);
            }

            throw new Error('not supported date or time format');
        };

        @initialize
        protected init(): void {
            const { reactionDisposers } = this;
            reactionDisposers.push(
                ...[
                    reaction(
                        () => config.isLoggedIn(),
                        (loggedIn) => loggedIn && this.loadSupportedLocales(),
                        { fireImmediately: true }
                    ),
                    reaction(
                        () => ({
                            loggedIn: config.isLoggedIn(),
                            country: this.localeStore.userCountry
                        }),
                        ({ country, loggedIn }) =>
                            loggedIn && this.loadCountries(country),
                        { fireImmediately: true }
                    )
                ]
            );

            reactionDisposers.push(
                reaction(
                    () => ({
                        locale: this.currentLocale
                    }),
                    ({ locale }) => {
                        if (locale) {
                            this.loadTranslationBundle(locale);

                            const formatDateTimeFormat =
                                new Intl.DateTimeFormat(locale, {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: false
                                });

                            const formatDateFormat = new Intl.DateTimeFormat(
                                locale,
                                {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit'
                                }
                            );

                            const percentNumberFormat = new Intl.NumberFormat(
                                locale,
                                {
                                    style: 'percent',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                }
                            );

                            const percentNumber2DigitsFormat =
                                new Intl.NumberFormat(locale, {
                                    style: 'percent',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                });

                            const timeFormat = new Intl.DateTimeFormat(locale, {
                                hour: '2-digit',
                                minute: '2-digit'
                            });

                            this.formatPercent = (
                                value: undefined | number | TaxPercentage
                            ) => {
                                if (value === undefined) {
                                    return '-';
                                }
                                return percentNumberFormat.format(
                                    (value as number) / 100
                                );
                            };

                            this.formatPercent2Digits = (
                                value: undefined | number | TaxPercentage
                            ) => {
                                if (value === undefined) {
                                    return '-';
                                }
                                return percentNumber2DigitsFormat.format(
                                    (value as number) / 100
                                );
                            };

                            this.formatDecimal = (
                                value: number | undefined,
                                disableGrouping = false
                            ) => {
                                if (value === undefined) {
                                    return '-';
                                }
                                return value.toLocaleString(locale, {
                                    useGrouping: !disableGrouping
                                });
                            };

                            this.formatDateTime = (value: Date) =>
                                formatDateTimeFormat.format(value);

                            this.formatDate = (value: Date) =>
                                formatDateFormat.format(value);

                            this.formatTime = (value: Date) =>
                                timeFormat.format(value);

                            this.formatZonedDateTime = (
                                value: IsoDateString<DATETIME>,
                                timezone: string
                            ) =>
                                momentTimezone
                                    .tz(value, timezone)
                                    .format('L, LT');

                            this.formatZonedDate = (
                                value: IsoDateString<DATETIME>,
                                timezone: string
                            ) => momentTimezone.tz(value, timezone).format('L');

                            this.formatZonedTime = (
                                value: IsoDateString<DATETIME>,
                                timezone: string
                            ) =>
                                momentTimezone.tz(value, timezone).format('LT');

                            this.dateTimeInputDateFormat = () =>
                                this.defaultDateFormat;
                            this.dateTimeInputTimeFormat = () =>
                                this.defaultTimeFormat;
                            this.dateTimeInputDateTimeFormat = () =>
                                this.defaultDateTimeFormat;
                        }
                    },
                    {
                        name: 'I18nStore#init-2',
                        fireImmediately: true
                    }
                )
            );

            reactionDisposers.push(
                reaction(
                    () => ({ locale: this.localeStore.currentLocale }),
                    ({ locale }) => {
                        if (locale) {
                            getStorage?.().setItem(
                                'locale',
                                JSON.stringify(locale)
                            );
                        }
                    }
                )
            );
        }

        @destroy
        protected destroy(): void {
            this.reactionDisposers.forEach((disposer) => disposer());
        }

        private loadSupportedLocales = async (): Promise<void> => {
            this.supportedLocales = await config.getSupportedLocales();
        };

        private loadCountries = async (
            country?: CountryCodeEnum
        ): Promise<void> => {
            if (!country || !config.getCountries) {
                return;
            }
            this._countries = await config.getCountries(country);
        };

        private getBrandedVariant(
            key: string,
            source: { [key: string]: string }
        ) {
            const { brandType } = this.multiBrandStore;

            const potentialBrandedVariantKey = `${key}.branded.${brandType.brandName}`;
            const brandedVariant = source[potentialBrandedVariantKey];

            if (!brandedVariant) {
                return;
            }
            return brandedVariant;
        }

        private async loadTranslationBundle(
            localeParam: string
        ): Promise<void> {
            if (typeof localeParam !== 'string') {
                return;
            }
            const loc = localeParam.replace(/"/g, '');
            const exact = config.locallySupportedLocales.find((l) => l === loc);
            const [lang] = loc.split('-');
            const locale =
                exact ||
                config.locallySupportedLocales.find((l) =>
                    l.startsWith(lang)
                ) ||
                config.locallySupportedLocales[0];

            const source: { [key: string]: string } = await (
                await fetch(
                    `https://intl.sportalliance.com/ml-online-pages/${
                        localesPathMappings[
                            locale as keyof typeof localesPathMappings
                        ] || 'en'
                    }.json`
                )
            ).json();

            const translate = (
                key: string | Translatable | null | undefined,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                params: any
            ): string => {
                if (!key) {
                    if (isDev()) {
                        console.warn('no i18n-key assigned');
                        return 'no i18n-key assigned';
                    }
                    return '';
                }
                const translated = isTranslatable(key)
                    ? key.translation
                    : this.getBrandedVariant(key, source) ?? source[key];
                if (!translated) {
                    if (isDev()) {
                        const p = params
                            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                              `${nbsp}[${Object.values(params || {}).join(
                                  `,${nbsp}`
                              )}]`
                            : '';
                        return `i18n${nbsp}not${nbsp}found:${nbsp}${key}${p}`;
                    }
                    return key.toString();
                }

                return replacePlaceHolder(translated, params);
            };

            this.translate = this.wrap(translate);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).translate = translate;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ((window as any).ml = (window as any).ml || {})['i18n-store'] =
                this;

            this.hasTranslation = (key) => Boolean(key && source[key]);
            this.version++;
        }

        public set currentLocale(_locale: string) {
            throw new Error('setting current locale is not allowed');
        }

        public get currentLocale(): string {
            const { currentLocale } = this.localeStore;
            if (currentLocale) {
                return currentLocale;
            }
            const cached = getStorage?.().getItem('locale');
            if (cached) {
                return String(JSON.parse(cached));
            }
            return navigator.language;
        }

        public get currentLang(): string {
            return this.localeStore.currentLang;
        }

        public get translationsReady(): boolean {
            return this.version > 0;
        }

        private wrap(
            fn: (
                key: string | Translatable | null | undefined,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ...params: any[]
            ) => string
        ): (
            key: string | Translatable | null | undefined,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...params: any[]
        ) => string {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const convertParams = (params: any[]) => {
                // note: allowed values for params are
                // [ 'value1', 'value2', 'value3' ] and
                // [ { '0': 'value1', '1': 'value2', '2': 'value3' } ]
                // params as string-array will be transformed to the latter syntax.
                if (params[0] !== undefined && typeof params[0] !== 'object') {
                    // eslint-disable-next-line no-param-reassign
                    params = [
                        params.reduce(
                            (
                                memo: { [key: string]: string | number },
                                param: string | number,
                                i: number
                            ) => {
                                memo[`${i}`] = param;
                                return memo;
                            },
                            {}
                        )
                    ];
                }
                return params;
            };

            return (key, ...params) => {
                if (isNoI18nMode()) {
                    if (isTranslatable(key)) {
                        return key.debug;
                    }
                    return key || '';
                }
                try {
                    if (
                        key &&
                        !isTranslatable(key) &&
                        !this.hasTranslation(key) &&
                        this.translationsReady
                    ) {
                        if (isDev()) {
                            console.warn(
                                `%ci18n missing: %c${key}`,
                                'color:#94999d',
                                'color:#000000'
                            );
                        } else if (isPossiblyUntranslatedKey(key)) {
                            config.onUntranslatedKey?.(key);
                        }
                    }
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    return fn(key, ...convertParams(params));
                } catch (e) {
                    console.warn((e as Error).message);

                    if (isTranslatable(key)) {
                        return key.translation;
                    }
                    return key || '';
                }
            };
        }

        public get currencyCode(): CurrencyCodeEnum {
            return this.localeStore.currencyCode;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        public get dateTimeTranslation(): any {
            return {
                weekdays: {
                    shorthand: [
                        this.translate('commons.dayofweek.sunday.short'),
                        this.translate('commons.dayofweek.monday.short'),
                        this.translate('commons.dayofweek.tuesday.short'),
                        this.translate('commons.dayofweek.wednesday.short'),
                        this.translate('commons.dayofweek.thursday.short'),
                        this.translate('commons.dayofweek.friday.short'),
                        this.translate('commons.dayofweek.saturday.short')
                    ],
                    longhand: [
                        this.translate('commons.dayofweek.sunday'),
                        this.translate('commons.dayofweek.monday'),
                        this.translate('commons.dayofweek.tuesday'),
                        this.translate('commons.dayofweek.wednesday'),
                        this.translate('commons.dayofweek.thursday'),
                        this.translate('commons.dayofweek.friday'),
                        this.translate('commons.dayofweek.saturday')
                    ]
                },
                months: {
                    shorthand: [
                        this.translate('charts.month.short.january'),
                        this.translate('charts.month.short.february'),
                        this.translate('charts.month.short.march'),
                        this.translate('charts.month.short.april'),
                        this.translate('charts.month.short.may'),
                        this.translate('charts.month.short.june'),
                        this.translate('charts.month.short.july'),
                        this.translate('charts.month.short.august'),
                        this.translate('charts.month.short.september'),
                        this.translate('charts.month.short.october'),
                        this.translate('charts.month.short.november'),
                        this.translate('charts.month.short.december')
                    ],
                    longhand: [
                        this.translate('commons.month.january'),
                        this.translate('commons.month.february'),
                        this.translate('commons.month.march'),
                        this.translate('commons.month.april'),
                        this.translate('commons.month.may'),
                        this.translate('commons.month.june'),
                        this.translate('commons.month.july'),
                        this.translate('commons.month.august'),
                        this.translate('commons.month.september'),
                        this.translate('commons.month.october'),
                        this.translate('commons.month.november'),
                        this.translate('commons.month.december')
                    ]
                },
                firstDayOfWeek: 1,
                weekAbbreviation: this.translate('commons.month.kw'),
                rangeSeparator: ' - ',
                scrollTitle: this.translate('datepicker.scrolltochange'),
                toggleTitle: this.translate('datepicker.clicktoswitch')
            };
        }

        public get countryOptions(): Option<CountryCodeEnum>[] {
            return this.countries
                .map<Option<CountryCodeEnum>>((pair) => ({
                    label: pair.value!,
                    value: pair.key!
                }))
                .sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    } else if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
        }

        public __ = (
            key: string | null | undefined,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...params: any[]
        ) =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            this.translate(key, ...params);

        public formatDateString = (date?: string | Date): string => {
            if (!date) {
                return '-';
            }
            return this.formatDate(
                typeof date === 'string' ? new Date(date) : date
            );
        };

        public formatTimeString = (date?: string | Date): string => {
            if (!date) {
                return '-';
            }
            return this.translate(
                'commons.x.o.clock',
                this.formatTime(
                    typeof date === 'string' ? new Date(date) : date
                )
            );
        };

        public formatDateTimeString = (date?: Date | string): string => {
            if (!date) {
                return '-';
            }
            const d = typeof date === 'string' ? new Date(date) : date;
            return this.translate('commons.x.o.clock', this.formatDateTime(d));
        };

        public getCountryName = (country: CountryCodeEnum): string => {
            if (!config.getCountries) {
                throw new Error(
                    "getCountryName and countryOptions won'n work without getCountries defined in configuration"
                );
            }

            const countryName = this.countries.find(
                ({ key }) => key === country
            );
            if (!countryName) {
                return '';
            }
            return countryName.value || '';
        };

        public formatCurrency = (
            value: number | MoneyDto | undefined
        ): string => this.localeStore.formatCurrency(value);
    }

    return I18nStore;
};
